@use '@/styles/utils/mixins.scss' as *;

.enquireNowForm.enquireFormWrapper {
  height: 100%;

  @include tab() {
    position: relative;
    height: calc(100% - 80px);
  }
}

.enquireNowFormInput {
  height: 40px;

  &::placeholder {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: $kiaMidnightBlack;
  }
}

.footerWrapper {
  margin-bottom: 36px;
}

.formItem {
  margin-bottom: 10px;
}
