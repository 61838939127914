@use '@/styles/utils/mixins.scss' as *;

.customSelect {
  .formLabel {
    font-size: 12px;
    line-height: 1.2;
    color: $kiaMidnightBlack;
    margin-bottom: 24px;
  }
  .formInput {
    border-color: $kiaSilver;
    height: auto;
    font-size: 36px;
    line-height: 1.2;
    font-family: 'KiaSignature';
    font-weight: 600;
    font-style: normal;
    border-color: transparent;
  }

  .formInput:focus-visible,
  .formInput[data-focus-visible] {
    border-color: transparent;
    box-shadow: 0px 0px 0px 0px transparent;
  }
  :global {
    .chakra-select__icon-wrapper {
      right: 0;
      background-color: $kiaPolarWhite;
      height: calc(100% + 3px);
    }
    option {
      font-size: 16px;
    }
  }
}
