@use '@/styles/utils/mixins.scss' as *;

.customSelect {
  width: 100%;
  margin-bottom: 18px;
  .ant-form-item .ant-form-item-label {
    text-align: left;
  }
  .ant-select {
    position: relative !important;
    min-width: auto !important;
  }

  .ant-select-selector {
    background: $kiaPolarWhite !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }

  .ant-select.ant-select-single.ant-select-show-search
    .ant-select-selector:hover {
    border-color: $kiaMidnightBlack !important;
  }

  .ant-select-focused .ant-select-selector {
    border-color: $kiaMidnightBlack !important;
    box-shadow: none !important;
  }

  .ant-form-item-row {
    flex-flow: column;
  }
}

.ant-select-dropdown {
  z-index: 1400 !important;
}
