@use '@/styles/utils/mixins.scss' as *;

.customSelect {
  :global {
    .chakra-select__icon-wrapper {
      right: 0;
      background-color: $kiaPolarWhite;
      height: calc(100% + 3px);
    }
  }
}
