@use '@/styles/utils/mixins.scss' as *;

.formLabel {
  font-size: 12px;
  line-height: 1.2;
  color: $kiaMidnightBlack;
  margin-bottom: 18px;
}
.formInput {
  border-color: $kiaSilver !important;
  height: auto !important;
  color: $kiaMidnightBlack;
  &::placeholder {
    color: $kiaMidnightBlack;
  }
}

select.formInput {
  option {
    border-radius: 0;
    color: $kiaMidnightBlack;
  }
}

.formInput:focus-visible,
.formInput[data-focus-visible] {
  border-color: $kiaMidnightBlack !important;
  box-shadow: 0px 1px 0px 0px $kiaMidnightBlack !important;
}
.floatingForm {
  position: relative;
  margin-top: 20px;
  .formLabel {
    top: 8px;
    left: 0;
    z-index: 2;
    position: absolute;
    background-color: $kiaPolarWhite;
    pointer-events: none;
    transform-origin: left top;
    font-size: 16px;
    margin-bottom: 0;
  }
  .formInput {
    padding: 8px 0;
    font-size: 16px;

    line-height: 1;
    &::placeholder {
      color: $kiaMidnightBlack;
    }
  }
  &:focus-within {
    .formLabel {
      transform: scale(0.88) translateY(-32px);
    }
  }
  input:not(:placeholder-shown) + label,
  .chakra-select__wrapper + label,
  textarea:not(:placeholder-shown) ~ label {
    transform: scale(0.88) translateY(-32px);
  }
}
.btn {
  background-color: $kiaMidnightBlack;
  border: 1px solid $kiaMidnightBlack;
  color: $kiaPolarWhite;
  font-family: 'KiaSignature';
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  padding: 13px 30px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
  display: flex;
  text-align: center;
  border-radius: 0;
  width: max-content;
  height: auto;
  span {
    display: inline-block;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      width: 0;
      background-color: $kiaPolarWhite;
      transition: width 0.3s;
    }
  }
  &:hover,
  &:active,
  &.active {
    background-color: $kiaMidnightBlack;
    border-color: $kiaMidnightBlack;
    color: $kiaPolarWhite;
    span {
      &::before {
        width: 100%;
      }
    }
  }
  &.disabled,
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &.btnSm {
    padding: 10px 24px;
  }
}
