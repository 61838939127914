@use '@/styles/utils/mixins.scss' as *;

.enquireNowFormContent {
  padding: 8px 0 13px;
  min-height: 460px;
}

.enquireNowFormWrapper {
  width: 100%;
  border: none;
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 1;
        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
        @include mob() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 1;
        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
        @include mob() {
          font-size: 24px;
          line-height: 1;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 1;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }
  }
}

.popiaNotice {
  margin-top: 20px;
  font-size: 12px;
  color: $kiaMidnightBlack;

  .popiaLink {
    margin-top: 20px;
    font-size: 14px;
    display: inline-flex;
    font-weight: 600;
    color: $kiaMidnightBlack;
    text-decoration: underline;
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
    &:hover {
      text-decoration: none;
    }
  }
}
