@use '@/styles/utils/mixins.scss' as *;

.newsLetterFormWrapper {
  height: 100%;
  margin-top: 24px;
  @include tab() {
    position: relative;
    height: calc(100% - 80px);
  }
}
