@use '@/styles/utils/mixins.scss' as *;

.newsLetterSentMessageWrapper {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 1;
        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
        @include mob() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 1;
        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
        @include mob() {
          font-size: 24px;
          line-height: 1;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 1.2;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }
  }
  .btn {
    background-color: $kiaMidnightBlack;
    border: 1px solid $kiaMidnightBlack;
    color: $kiaPolarWhite;
    font-family: 'KiaSignature';
    font-size: 16px;
    line-height: 1.2;
    font-weight: 600;
    padding: 13px 30px;
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
    display: flex;
    text-align: center;
    border-radius: 0;
    width: max-content;
    height: auto;
    span {
      display: inline-block;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background-color: $kiaPolarWhite;
        transition: width 0.3s;
      }
    }
    &:hover,
    &:active,
    &.active {
      background-color: $kiaMidnightBlack;
      border-color: $kiaMidnightBlack;
      color: $kiaPolarWhite;
      span {
        &::before {
          width: 100%;
        }
      }
    }
    &.disabled,
    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    &.btnSm {
      padding: 10px 24px;
    }
  }
}
