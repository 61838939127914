@use '@/styles/utils/mixins.scss' as *;

.locationButton {
  margin-left: auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $filterBlockBorder;
  color: $kiaMidnightBlack;
  font-weight: bold;
  padding: 9px 20px;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  cursor: pointer;
  margin-bottom: 16px;
  .locationIcon {
    width: 16px;
    height: 16px;
  }
}
