@use '@/styles/utils/mixins.scss' as *;

.newsLetterSignupWrapper {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleLg {
        font-size: 36px;
        line-height: 1.2;
      }
      &.titleMd {
        font-size: 24px;
        line-height: 1.2;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 1.2;
      }
    }
    .titleText {
      font-weight: 400;
      font-size: 16px;
    }
  }
}
